import React from "react";
import "./bodyEight.scss";

export function BodyEight() {
  return (
    <section className="wrapper_section_eight">
      <div className="contact1">
        <p className="secondaryColor">Contacto general:</p>
        <p className="thirdColor">comunicacion@hospitalescruzazul.com.mx</p>
      </div>
      <div className="contact2">
        <p className="secondaryColor">Quejas y sugerencias:</p>
        <p className="thirdColor">buzondequejas@hospitalescruzazul.com.mx</p>
      </div>
    </section>
  );
}
