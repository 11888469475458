import "./content.scss";
// import { Header } from ".//header/header js";
import { BodyHome } from "../bodyHome/bodyHome";
import { BodyThree } from "../bodyThree/bodyThree";
import { BodyTwo } from "../bodyTwo/bodyTwo";

import { BodyFour } from "../bodyFour/bodyFour";
import { BodyFive } from "../bodyFive/bodyFive";
import { BodySix } from "../bodySix/bodySix";
import { BodySeven } from "../bodySeven/bodySeven";
import { BodyEight } from "../bodyEight/bodyEight";


function Content() {
  return (
    <section className="wrapper_section_content">
      <BodyHome />
      <BodyTwo />
      <BodyThree />
      <BodyFour />
      <BodyFive />
      <BodySix />
      <BodySeven />
      <BodyEight/>
      {/* <Footer/> */}
    </section>
  );
}

export default Content;
