import React, { useRef } from "react";
import "./bodyFour.scss";
import ambulance from "../../assets/imgs/ambulance.png";
import franja from "../../assets/imgs/franja.png";

export function BodyFour() {
  const myRef = useRef();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleOpen1 = () => {
    setOpen1(!open1);
  };
  const handleOpen2 = () => {
    setOpen2(!open2);
  };
  const handleOpen3 = () => {
    setOpen3(!open3);
  };
  const handleOpen4 = () => {
    setOpen4(!open4);
  };
  return (
    <section className="wrapper_section_four" id="oaxaca">
      <div className="topFour">
        <div className="topTitle">
          <p>HOSPITALES CRUZ AZUL</p>
          <p className="secondText">OAXACA</p>
        </div>
        <div className="emergency">
          <div className="redEmergency">
            Emergencias: <a href="tel:9727260914">972 726 09 14</a>
          </div>
          <div className="ambulance">
            <img src={ambulance} alt="ambulancia" />
          </div>
        </div>
      </div>
      <div className="bottomFour">
        <div className="textContainer">
          <p className="titleBlue">CONSULTA EXTERNA</p>
          <div className="ltlText secondaryColor text-xs">
            <ul>
              <li>Medicina general</li>
              <li>Odontología</li>
              <li>Ginecología y obstetricia</li>
              <li>Pediatría</li>
              <li>Medicina interna</li>
              <li>Geriatría</li>
              <li>Cirugía general</li>
              <li>Traumatología y ortopedia</li>
              <li>Cardiología</li>
              <li>Psicología</li>
              <li>Oftalmología</li>
              <li>Otorrinolaringología</li>
              <li>Medicina física y rehabilitación</li>
              <li>Nutrición</li>
            </ul>
          </div>
          <p className="titleBlue">HOSPITALIZACIÓN</p>
          <div className="ltlText secondaryColor text-xs">
            <ul>
              <li>Área quirúrgica y tocología</li>
              <li>Central de equipos y esterilización</li>
              <li>Endoscopia</li>
            </ul>
          </div>
          <p className="titleBlue">EPIDEMIOLOGÍA</p>
          <div className="ltlText secondaryColor text-xs">
            <ul>
              <li>Vacunación</li>
            </ul>
          </div>
        </div>
        <div className="textContainer">
          <p className="titleBlue">SERVICIOS AUXILIARES DE DIAGNÓSTICO</p>
          <div className="ltlText secondaryColor text-xs">
            <ul>
              <li>Laboratorio</li>
              <li>Imagenología</li>
            </ul>
          </div>
          <p className="titleBlue">PROCEDIMIENTOS QUIRÚRGICOS</p>
          <div className="ltlText secondaryColor text-xs">
            <ul>
              <li>Cirugía general</li>
              <li>Traumatología y ortopedia</li>
              <li>Ginecología y obstetricia</li>
              <li>Otorrinolaringología</li>
              <li>Oftalmología</li>
              <li>Anestesiología</li>
            </ul>
          </div>
          <p className="titleBlue">BANCO DE SANGRE</p>
          <p className="titleBlue">HEMODIÁLISIS</p>
          <p className="titleBlue">FARMACIA</p>
          <p className="titleBlue">SERVICIO DE URGENCIAS</p>
        </div>
        <div className="blueContainer">
          <div className="blueInfo">
            <p>Conmutador: 972 726 95 00</p>
            <p className="lighterText">Citas médicas: Ext. 2232</p>
            <p>Servicio urgencias: Ext. 2295</p>
            <br />
            <p className="lightText text-xs">
              Dirección: Calle Galeana No.9, Col. Progreso,
            </p>
            <p className="lightTextpad text-xs ">Lagunas, Oaxaca, C.P. 70380</p>
          </div>
          <div className="blueBorde">
            <img src={franja} alt="franja" className="franja" />
          </div>
        </div>
      </div>
      {/* Menú Versión Ipad/Celular */}
      <div className="topFourIpad">
        <div className="topTitleIpad">
          <p>HOSPITALES CRUZ AZUL</p>
          <p className="secondTextIpad">OAXACA</p>
        </div>
      </div>
      <br />
      <div className="bottomFourIpad">
        <div className="menus">
          <div className="menuList">
            <div className="listado">
              <button className="titleBlueIpad" onClick={handleOpen}>
              {open ? ( <ion-icon name="caret-down-sharp" /> ) : <ion-icon name="caret-forward-sharp" />}
                CONSULTA EXTERNA
                {open ? (
                  <div className="ltlTextIpad secondaryColor text-xs">
                    <ul>
                      <li>Medicina general</li>
                      <li>Odontología</li>
                      <li>Ginecología y obstetricia</li>
                      <li>Pediatría</li>
                      <li>Medicina interna</li>
                      <li>Geriatría</li>
                      <li>Cirugía general</li>
                      <li>Traumatología y ortopedia</li>
                      <li>Cardiología</li>
                      <li>Psicología</li>
                      <li>Oftalmología</li>
                      <li>Otorrinolaringología</li>
                      <li>Medicina física y rehabilitación</li>
                      <li>Nutrición</li>
                    </ul>
                  </div>
                ) : null}
              </button>
            </div>
            <div className="listado1">
              <button className="titleBlueIpad" onClick={handleOpen1}>
              {open1 ? ( <ion-icon name="caret-down-sharp" /> ) : <ion-icon name="caret-forward-sharp" />}
                HOSPITALIZACIÓN
                {open1 ? (
                  <div className="ltlTextIpad secondaryColor text-xs">
                    <ul>
                      <li>Área quirúrgica y tocología</li>
                      <li>Central de equipos y esterilización</li>
                      <li>Endoscopia</li>
                    </ul>
                  </div>
                ) : null}
              </button>
            </div>
            <div className="listado2">
              <button className="titleBlueIpad" onClick={handleOpen2}>
              {open2 ? ( <ion-icon name="caret-down-sharp" /> ) : <ion-icon name="caret-forward-sharp" />}
                EPIDEMIOLOGÍA
                {open2 ? (
                  <div className="ltlTextIpad secondaryColor text-xs">
                    <ul>
                      <li>Vacunación</li>
                    </ul>
                  </div>
                ) : null}
              </button>
            </div>
            <div className="listado3">
              <button className="titleBlueIpad" onClick={handleOpen3}>
              {open3 ? ( <ion-icon name="caret-down-sharp" /> ) : <ion-icon name="caret-forward-sharp" />}
                SERVICIOS AUXILIARES DE DIAGNÓSTICO
                {open3 ? (
                  <div className="ltlTextIpad secondaryColor text-xs">
                    <ul>
                      <li>Laboratorio</li>
                      <li>Imagenología</li>
                    </ul>
                  </div>
                ) : null}
              </button>
            </div>
            <div className="listado4">
              <button className="titleBlueIpad" onClick={handleOpen4}>
              {open4 ? ( <ion-icon name="caret-down-sharp" /> ) : <ion-icon name="caret-forward-sharp" />}
                PROCEDIMIENTOS QUIRÚRGICOS
                {open4 ? (
                  <div className="ltlTextIpad secondaryColor text-xs">
                    <ul>
                      <li>Cirugía general</li>
                      <li>Traumatología y ortopedia</li>
                      <li>Ginecología y obstetricia</li>
                      <li>Otorrinolaringología</li>
                      <li>Oftalmología</li>
                      <li>Anestesiología</li>
                    </ul>
                  </div>
                ) : null}
              </button>
            </div>
          </div>
          <div className="noMenuList">
            <p className="titleBlueIpad">BANCO DE SANGRE</p>
            <p className="titleBlueIpad">HEMODIÁLISIS</p>
            <p className="titleBlueIpad">FARMACIA</p>
            <p className="titleBlueIpad">SERVICIO DE URGENCIAS</p>
          </div>
        </div>
      </div>
      <div className="emergencyIpad">
        <div className="redEmergency">
          Emergencias: <a href="tel:9727260914">972 726 09 14</a>
        </div>
        <div className="ambulanceIpad">
          <img src={ambulance} alt="ambulancia" />
        </div>
      </div>
      <div className="blueContainerIpad">
          <div className="blueInfoIpad">
            <p>Conmutador: 972 726 95 00</p>
            <p className="lighterTextIpad">Citas médicas: Ext. 2232</p>
            <p>Servicio urgencias: Ext. 2295</p>
            <br />
            <p className="lightTextIpad text-xs">
              Dirección: Calle Galeana No.9, Col. Progreso,
            </p>
            <p className="lightTextpadIpad text-xs ">
              Lagunas, Oaxaca, C.P. 70380
            </p>
          </div>
          <div className="blueBordeIpad">
            <img src={franja} alt="franja" className="franjaIpad" />
          </div>
        </div>
    </section>
  );
}
