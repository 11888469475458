import React from "react";
import "./bodySix.scss";
import franja from "../../assets/imgs/franja.png";

export function BodySix() {
  return (
    <section className="wrapper_section_six" id="hidalgo">
      <div className="topSix">
        <div className="topTitle">
          <p>HOSPITALES CRUZ AZUL</p>
          <p className="secondText">HIDALGO</p>
        </div>
      </div>
      <br/>
      <div className="bottomSix">
        <div className="menus">
          <div className="textContainer">
            <p className="titleBlue">CONSULTA GENERAL</p>
            <p className="titleBlue">MEDICINA INTERNA</p>
            <p className="titleBlue">TRAUMATOLOGÍA</p>
            <p className="titleBlue">MEDICINA INTEGRADA</p>
            <p className="titleBlue">GINECOLOGÍA</p>
            <p className="titleBlue">PEDIATRÍA</p>
            <p className="titleBlue">ODONTOLOGÍA</p>
            <p className="titleBlue">GERIATRÍA</p>
            <p className="titleBlue">REUMATOLOGÍA</p>
            <p className="titleBlue">CLÍNICA DEL DOLOR</p>
          </div>
          <div className="textContainer">
            <p className="titleBlue">ANGIOLOGÍA</p>
            <p className="titleBlue">CIRUGÍA ONCOLÓGICA</p>
            <p className="titleBlue">NUTRICIÓN</p>
            <p className="titleBlue">HEMODIÁLISIS</p>
            <p className="titleBlue">NEFROLOGÍA</p>
            <p className="titleBlue">LABORATORIO</p>
            <p className="titleBlue">FARMACIA</p>
            <p className="titleBlue">OTORRINOLARINGOLOGÍA</p>
            <p className="titleBlue">TERAPIA FÍSICA Y REHABILITACIÓN</p>
          </div>
          <div className="blueContainer">
            <div className="blueInfo">
              <p>Conmutador: 771 717 77 20</p>
              <p className="lighterText">Citas médicas: Ext. 4322</p>
              <p>Servicio urgencias: Ext. 4321</p>
              <p className="lighterText">
                Líneas directas: 773 688 3990 - 773 264 0106
              </p>
              <p className="lighterText">Hospitalización: Ext. 4323</p>
              <p className="lightText text-xs">
                Dirección: Calle Zimapán No. 22, Col. San Miguel Vindhó,
              </p>
              <p className="lightTextpad text-xs ">
                Tula de Allende, Hidalgo C.P. 42842
              </p>
            </div>
            <div className="blueBorde">
              <img src={franja} alt="franja" className="franja" />
            </div>
          </div>
        </div>
        <div className="blueContainerMobile">
          <div className="blueInfo">
            <p>Conmutador: 771 717 77 20</p>
            <p className="lighterText">Citas médicas: Ext. 4322</p>
            <p>Servicio urgencias: Ext. 4321</p>
            <p className="lighterText">
              Líneas directas: 773 688 3990 - 773 264 0106
            </p>
            <p className="lighterText">Hospitalización: Ext. 4323</p>
            <p className="lightText text-xs">
              Dirección: Calle Zimapán No. 22, Col. San Miguel Vindhó,
            </p>
            <p className="lightTextpad text-xs ">
              Tula de Allende, Hidalgo C.P. 42842
            </p>
          </div>
          <div className="blueBorde">
            <img src={franja} alt="franja" className="franja" />
          </div>
        </div>
      </div>
    </section>
  );
}
