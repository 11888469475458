import React from "react";
import "./footer.scss";
import whiteLogo from "../../assets/imgs/whiteLogo.png";

export function Footer() {
  return (
    <section className="wrapper_section_footer">

      <img src={whiteLogo} alt="ambulancia" className="whiteLogo"/>

      <div className="footer2">
        <p className="rigthsReserved">Hospitales Cruz Azul ® Todos los derechos reservados {(new Date().getFullYear())}.</p>        
      </div>
      {/* <div className="footer3">
        <p className="privacyNotice">Aviso de privacidad</p> 
      </div> */}
    </section>
  );
}
